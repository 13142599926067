

export default function Data() {
    return (
        {
            lp_campaign_id: "3273",
            lp_supplier_id: "3273",
            lp_key: "vl3bjn0ur",
            lp_subid1: "",
            lp_subid2: "",
            universal_leadid: "",
            ZipCode: "",
            VehicleYear: "",
            VehicleMake: "",
            VehicleModel: "",
            AddSecondVehicle: "",
            SecondVehicleYear: "",
            SecondVehicleMake: "",
            SecondVehicleModel: "",
            AddThirdVehicle: "",
            ThirdVehicleYear: "",
            ThirdVehicleMake: "",
            ThirdVehicleModel: "",
            AutoInsurancePast30days: "",
            CurrentInsurer: "",
            HowManyDrivers: "",
            Gender: "",
            Married: "",
            CreditScore: "",
            AtFaultAccidentsPast3years: "",
            TicketsPast3years: "",
            DUIpast3years: "",
            SecondDriverGender: "",
            SecondDriverMarried: "",
            SecondDriverAccidents3years: "",
            SecondDriverTickets3years: "",
            SecondDriverDUI3years: "",
            SecondDriverBirthdate: "",
            ThirdDriverGender: "",
            ThirdDriverMarried: "",
            ThirdDriverAccidents3years: "",
            ThirdDriverTickets3years: "",
            ThirdDriverDUI3years: "",
            ThirdDriverBirthdate: "",
            OwnOrRentHome: "",
            ReceiveHomeInsuranceQuotes: "",
            ReceiveRenterInsuranceQuotes: "",
            ServedInMilitary: "",
            BirthDate: "",
            FirstName: "",
            LastName: "",
            SecondDriverFirstName: "",
            SecondDriverLastName: "",
            ThirdDriverFirstName: "",
            ThirdDriverLastName: "",
            Address: "",
            City: "",
            State: "",
            AddressZIP: "",
            Email: "",
            ReceiveEmailRates: "Yes",
            PhoneNumber: "",
            DOBDate: "",
            DOBMonth: "",
            DOBYear: "",
            SecondDriverDOBDate: "",
            SecondDriverDOBMonth: "",
            SecondDriverDOBYear: "",
            ThirdDriverDOBDate: "",
            ThirdDriverDOBMonth: "",
            ThirdDriverDOBYear: "",
            IpAddress: "",
            UserAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.69 Safari/537.36",
            Terms: 'By clicking Show My Quotes and submitting this form, I am providing express written consent to being contacted by you or by one or more agents or brokers of your Marketing Partners which companies I agree may reach me to discuss my interest, including offers of insurance, at the phone number and/or email address I have provided to you in submitting this form and/or additional information obtained. I consent by electronic signature to being contacted by telephone (via call and/or text) for marketing/telemarketing purposes at the phone number I provided in this form, even if my phone number is listed on a Do Not Call Registry, and I agree that such contact may be made using an automatic telephone dialing system and/or an artificial or prerecorded voice (standard call, text message, and data rates apply). I can revoke my consent at any time. I also understand that my agreement to be contacted is not a condition of purchasing any property, goods or services, and that I may email support@ratenavigators.com to request an insurance quote. By clicking Show My Quotes and submitting this form, I affirm that I have read and agree to this websiteu2019s Privacy Policy and Terms of Use, including the arbitration provision and the E-SIGN Consent.',
            Form: "short",
            TransactionID: "",
            ss: "",
            sr: "",
            src: "",
            sub1: "",
            sub2: "",
            sub3: "",
            sub4: "",
            sub5: "",
            VehicleTrim: "",
            OwnOrLease: "Owned",
            PrimaryUse: "commute",
            AnnualMileage: "12500",
            Coverage: "Lower Level",
            SecondVehicleTrim: "",
            SecondVehicleOwnOrLease: "Owned",
            SecondVehiclePrimaryUse: "commute",
            SecondVehicleAnnualMileage: "12500",
            SecondVehicleCoverage: "Lower Level",
            ThirdVehicleTrim: "",
            ThirdVehicleOwnOrLease: "Owned",
            ThirdVehiclePrimaryUse: "commute",
            ThirdVehicleAnnualMileage: "12500",
            ThirdVehicleCoverage: "Lower Level",
            EducationLevel: "bachelors_degree",
            Occupation: "Other Non-technical",
            SecondDriverRelation: "",
            SecondDriverEducationLevel: "bachelors_degree",
            SecondDriverOccupation: "Other Non-technical",
            ThirdDriverRelation: "",
            ThirdDriverEducationLevel: "bachelors_degree",
            ThirdDriverOccupation: "Other Non-technical",
            HowCanWeHelpYou: "",
            aff: ""
        }
    )
}
